<template>
  <div>
    <Echart
      :options="options"
      id="bottomLeftChart"
      height="480px"
      width="100%"
      @getParams="getParams"
    ></Echart>
  </div>
</template>

<script>
import Echart from '@/common/echart'
import bus from '@/util/bus'
export default {
  data () {
    return {
      options: {},
    };
  },
  components: {
    Echart,
  },
  props: {
    cdata: {
      type: Object,
      default: () => ({})
    },
  },
  watch: {
    cdata: {
      handler (newData) {
        this.options = {
          title: {
            text: "",
          },
          dataZoom: [
            {
              type: "inside",
              show: true,
              start: 0,
              end: this.cdata.end,
              xAxisIndex: [0],
            }
          ],
          tooltip: {
            trigger: "axis",
            backgroundColor: "rgba(255,255,255,0.1)",
            axisPointer: {
              type: "shadow",
              label: {
                show: true,
                backgroundColor: "#7B7DDC",
              }
            },
            textStyle: { // 提示框浮层的文本样式。
              color: '#fff',
              fontStyle: 'normal',
              fontWeight: 'normal',
              fontFamily: 'sans-serif',
              fontSize: 16,
            },
          },
          legend: {
            data: ["收益", "成本"],
            textStyle: {
              color: "#B4B4B4",
              fontSize: '16'
            },
            top: "0%",
            right: '5%',
            itemWidth: 40, // 图例标记的图形宽度。
            itemGap: 20, // 图例每项之间的间隔。
            itemHeight: 20, //  图例标记的图形高度。
          },
          grid: {
            x: "5%",
            width: "92%",
            y: "4%"
          },
          xAxis: {
            data: newData.category,
            axisLine: {
              lineStyle: {
                color: "#B4B4B4"
              }
            },
            axisTick: {
              show: false
            },
            inverse:true
          },
          yAxis: [
            {
              name: '(元)',
              nameGap: 5,
              nameTextStyle:{
                color: '#fff',
                fontSize: 14
              },
              splitLine: { show: false },
              axisLine: {
                lineStyle: {
                  color: "#B4B4B4"
                }
              },
              axisLabel: {
                formatter: "{value} "
              }
            }
          ],
          series: [

            {
              name: "收益",
              type: "bar",
              barGap: "-100%",
              barWidth: 16,
              itemStyle: {
                normal: {
                  barBorderRadius: 10,
                  color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    { offset: 0, color: "rgba(156,107,211,0.8)" },
                    { offset: 0.2, color: "rgba(156,107,211,0.5)" },
                    { offset: 1, color: "rgba(156,107,211,0.2)" }
                  ])
                }
              },
              z: -12,
              data: newData.lineData
            },
            {
              name: "成本",
              type: "bar",
              barWidth: 16,
              itemStyle: {
                normal: {
                  barBorderRadius: 10,
                  color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    { offset: 0, color: "#956FD4" },
                    { offset: 1, color: "#3EACE5" }
                  ])
                }
              },
              data: newData.barData
            },
          ]
        }
      },
      immediate: true,
      deep: true
    },

  },
  mounted() {
  },
  methods:{
    getParams(params){
      //console.log(params)
      bus.$emit('clickBar',params)
    }
  },
}
</script>
