<template>
  <div class="body">
    <!-- 年度开工率 -->
    <Echart
      :options="options"
      id="bottomLeftChart"
      height="450px"
      width="100%"
    ></Echart>
  </div>
</template>

<script>
  import api from '../../util/extra-api'
  import Echart from '../../common/echart'
export default {
  data () {
    return {
      options: {},
    };
  },
  components: {
    Echart
  },
  props: {
    cdata: {
      type: Object,
      default: () => ({})
    },
    status:{
      type: Number,
    }
  },
  watch: {
    cdata: {
      handler (newData) {
        // this.option = {
        //   tooltip: {
        //     trigger: 'axis',
        //     axisPointer: {
        //       type: 'shadow',
        //       label: {
        //         show: true
        //       }
        //     }
        //   },
        //   toolbox: {
        //     show: true,
        //     feature: {
        //       mark: { show: true },
        //       dataView: { show: true, readOnly: false },
        //       magicType: { show: true, type: ['line', 'bar'] },
        //       restore: { show: true },
        //       saveAsImage: { show: true }
        //     }
        //   },
        //   calculable: true,
        //   legend: {
        //     data: ['Growth', '入库', '消耗'],
        //     itemGap: 5
        //   },
        //   grid: {
        //     top: '12%',
        //     left: '1%',
        //     right: '10%',
        //     containLabel: true
        //   },
        //   xAxis: [
        //     {
        //       type: 'category',
        //       data: newData.category
        //     }
        //   ],
        //   yAxis: [
        //     {
        //       type: 'value',
        //       name: 'Budget (million USD)',
        //       axisLabel: {
        //         formatter: function (a) {
        //           a = +a;
        //           return isFinite(a) ? echarts.format.addCommas(+a / 1000) : '';
        //         }
        //       }
        //     }
        //   ],
        //   dataZoom: [
        //     {
        //       show: true,
        //       start: 94,
        //       end: 100
        //     },
        //     {
        //       type: 'inside',
        //       start: 94,
        //       end: 100
        //     },
        //     {
        //       show: true,
        //       yAxisIndex: 0,
        //       filterMode: 'empty',
        //       width: 30,
        //       height: '80%',
        //       showDataShadow: false,
        //       left: '93%'
        //     }
        //   ],
        //   series: [
        //     {
        //       name: '出库',
        //       type: 'bar',
        //       data: newData.barData
        //     },
        //     {
        //       name: '消耗',
        //       type: 'bar',
        //       data: newData.barData
        //     }
        //   ]
        // };
        this.options = {
          dataZoom: [
            {
              type: "inside",
              show: true,
              start: 0,
              end: newData.end,
              xAxisIndex: [0],
            }
          ],

          title: {
            text: "",
          },
          tooltip: {
            trigger: "axis",
            backgroundColor: "#7bbfea",
            axisPointer: {
              type: "shadow",
              label: {
                show: true,
                backgroundColor: "#7B7DDC"
              }
            }
          },
          // legend: {
          //   data: ["品类收入排行", "品类销售排行"],
          //   textStyle: {
          //     color: "#B4B4B4"
          //   },
          //   top: "0%"
          // },
          grid: {
            left: '15',
            right: '0',
            bottom: '10',
            top: '25',
            containLabel: true
          },
          xAxis: {
            data: newData.category,
            axisLine: {
              lineStyle: {
                color: "#B4B4B4"
              }
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              interval:0,
              // rotate: -10,
              formatter:function(value)
              {
                // debugger
                var ret = "";//拼接加\n返回的类目项
                var maxLength = 2;//每项显示文字个数
                var valLength = value.length;//X轴类目项的文字个数
                var rowN = Math.ceil(valLength / maxLength); //类目项需要换行的行数
                if (rowN > 1)//如果类目项的文字大于3,
                {
                  for (var i = 0; i < 5; i++) {
                    var temp = "";//每次截取的字符串
                    var start = i * maxLength;//开始截取的位置
                    var end = start + maxLength;//结束截取的位置
                    //这里也可以加一个是否是最后一行的判断，但是不加也没有影响，那就不加吧
                    temp = value.substring(start, end) + "\n";
                    ret += temp; //凭借最终的字符串
                  }
                  return ret;
                }
                else {
                  return value;
                }
              }
            }
          },
          yAxis: [
            {
              type: 'value',
              name: this.status === 0 ? '(元)' : '(kg)',
              nameGap: 10,
              nameTextStyle:{
                color: '#fff',
                fontSize: 14
              },

              splitLine: { show: false },
              axisLine: {
                show: true,
                lineStyle: {
                  color: "#B4B4B4"
                }
              },

              axisLabel: {
                formatter: "{value} "
              }
            },
            // {
            //   splitLine: { show: false },
            //   axisLine: {
            //     lineStyle: {
            //       color: "#B4B4B4"
            //     }
            //   },
            //   axisLabel: {
            //     formatter: "{value} "
            //   }
            // }
          ],
          series: [
            {
              name: '入库',
              type: "bar",
              barWidth: 10,
              itemStyle: {
                normal: {
                  // barBorderRadius: 5,
                  color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    { offset: 0, color: this.cdata.color[0] },
                    { offset: 1, color: this.cdata.color[1] }
                  ]),
                  label: {
                    show: false,		//开启显示
                    position: 'top',	//在上方显示
                    textStyle: {	    //数值样式
                      color: 'white',
                      fontSize: 14
                    }
                  }
                }
              },
              data: newData.barData2
            },
            {
              name: '消耗',
              type: "bar",
              barWidth: 10,
              itemStyle: {
                normal: {
                  // barBorderRadius: 5,
                  color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    { offset: 0, color: '#00ae9d' },
                    { offset: 1, color: '#fdb933' }
                  ]),
                  label: {
                    show: false,		//开启显示
                    position: 'top',	//在上方显示
                    textStyle: {	    //数值样式
                      color: 'white',
                      fontSize: 14
                    }
                  }
                }
              },
              data: newData.barData1
            },

          ]
        }
      },
      immediate: true,
      deep: true
    },
  },
}
</script>
<style>
  .body{
    height: 550px;
  }

</style>
