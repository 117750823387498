<template>
  <div >
    <dv-loading class="loading" v-show="loading"></dv-loading>
    <Chart :cdata="cdata" v-if="showChart"/>
  </div>
</template>

<script>
import Chart from './chart.vue'
import api from "../../util/extra-api";
import bus from '@/util/bus'
export default {
  data () {
    return {
      cdata: {
        category: [

        ],
        lineData: [

        ],
        barData: [

        ],
        rateData: [],
        startTime: null,
        endTime: null,
        end: 100
      },
      showChart: false,
      loading: true
    };
  },
  components: {
    Chart,
  },
  mounted () {
    if (localStorage.getItem("time5_5")) {
      let time1 = JSON.parse(localStorage.getItem('time5_5'))
      this.startTime = time1.startTime
      this.endTime = time1.endTime
    }
    this.setData();
    this.getChart3()
    bus.$on('refresh',params =>{
      //console.log("来自management数据  成本收益统计",params)
      this.startTime = params.startTime
      this.endTime = params.endTime
      this.getChart3()
    })
  },
  methods: {
    // 根据自己的业务情况修改

    setData () {
      for (let i = 0; i < this.cdata.barData.length -1; i++) {
        let rate = this.cdata.barData[i] / this.cdata.lineData[i];
        this.cdata.rateData.push(rate.toFixed(2));
      }
    },
    getChart3(){
      this.loading = true
      this.showChart = false
      let data = {
        companyId: localStorage.getItem('company_id'),
        type: localStorage.getItem('type_1'),
        startTime: this.startTime,
        endTime: this.endTime
      }
      api.get('/v1/wx/companyScreen/chartTwo3',data).then(res =>{
        //console.log("chartTwo3",res)
        if(res.code === 200){
          this.cdata.category = []
          this.cdata.lineData = []
          this.cdata.barData = []
          if(res.data.length >= 25){
            this.cdata.end = 25 / res.data.length * 100
          }else{
            this.cdata.end = 100
          }
          // let money=0
          // let money1=0
          res.data.forEach((item,index) =>{
            // money += item.income
            // money1 += item.expenditure
            this.cdata.category.push(item.day)
            this.cdata.lineData.push(item.income)
            this.cdata.barData.push(item.expenditure)
            if(index == res.data.length - 1){
              this.showChart = true
            }
          })
          // console.log(money,money1,12321123);
          //this.data = res.data
          // this.cdata.category = this.data.incomeRankList.map(m => m.goodsName)
          // this.cdata.barData = this.data.incomeRankList.map(m => m.totalMoney)
          // this.show = true
        }
        this.loading  = false
      })
    },
  }
};
</script>

<style lang="scss" scoped>
</style>
