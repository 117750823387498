<template>
  <div class="body">
    <el-popover
            placement="bottom"
            width="100%"
            trigger="click"
            v-model="showPopover"
            transition="el-zoom-in-top"
    >
      <div style="padding: 20px">
        <div class="p15 tc f16">
          时间筛选
        </div>
        <div>
          <el-date-picker
                  v-model="value"
                  type="datetimerange"
                  align="right"
                  unlink-panels
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  :picker-options="pickerOptions">
          </el-date-picker>
        </div>
        <div class="mt10 tr">
          <button class="btn-reset " @click="timeConfirm(0)">
            重置
          </button>
          <button class="btn-add" @click="timeConfirm(1)">
            确定
          </button>
        </div>
      </div>
      <!--          <el-button slot="reference">click 激活</el-button>-->
      <div slot="reference" class="box-i-time1">
        <i  class="el-icon-time i-time"></i>
      </div>
    </el-popover>
    <div class="c-head">

      <div class="df">
        <div @click="changeType(2)" class="box-btn bgc-956">
        </div>
        <div>
          全部
        </div>
      </div>
      <div class="df ">
        <div @click="changeType(0)" class="box-btn bgc-8E7">

        </div>
        <div>
          重点物资
        </div>
      </div>
    </div>
<!--    <div class="c-head-left">-->

<!--      <div class="df ">-->
<!--        <div @click="changeStatus(1)" class="box-btn bgc-33a">-->

<!--        </div>-->
<!--        <div>-->
<!--          重量-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="df">-->
<!--        <div @click="changeStatus(0)" class="box-btn bgc-ffd">-->
<!--        </div>-->
<!--        <div>-->
<!--          货值<i v-if="cdata.status == 0">({{totalMoney}}元)</i>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
    <dv-loading class="loading" v-if="loading"></dv-loading>
    <Chart v-else :cdata="cdata" :status="cdata.status"/>
  </div>
</template>

<script>
import Chart from './chart.vue'
import api from "../../util/extra-api";
import {formatTime} from "../../util/util";
export default {
  data () {
    return {
      data: null,
      cdata: {
        category: [],
        lineData: [],
        barData1: [],
        barData2: [],
        rateData: [],
        type: 0,
        color: ['#3EACE5','#00ae9d'],
        status: 1,
        end: 100
      },
      type: 2,
      totalMoney: 0,
      startTime: '',
      endTime: '',
      showPopover: false,
      value: '',
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      loading: true
    };
  },
  components: {
    Chart,
  },
  mounted () {
    this.getChart8()

  },
  methods: {
    timeConfirm(type){
      if(type === 1){
        this.startTime = formatTime(this.value[0],'yyyy-MM-dd HH:mm:ss')
        this.endTime = formatTime(this.value[1],'yyyy-MM-dd HH:mm:ss')
      }else{
        this.startTime = ''
        this.endTime = ''
        this.value = ['','']
      }
      this.getChart8()
      // this.$refs.blChart.parentHandleclick({startTime: this.startTime,endTime: this.endTime});
      this.showPopover = false
    },
    changeType(type){
      this.type = type
      this.$emit('changeType',type)
      if(type === 2){
        this.cdata.color = ['#3EACE5','#00ae9d']
      }else if(type === 0){
        this.cdata.color = ['#8E7FC2','#47A6E4']
      }
      this.getChart8()
    },
    changeStatus(status){
      this.cdata.status = status
      this.getChart8()
    },
    getChart8(){
      this.loading = true
      api.get('/v1/wx/companyScreen/suppliesRank',{companyId: localStorage.getItem('company_id'),type: this.type,startTime: this.startTime,endTime: this.endTime}).then(res =>{
        if(res.code === 200){
          this.data =res. data
          this.data = res.data
          this.data.sort((a,b) => b.ck - a.ck)
          this.cdata.category = this.data.map(m => m.goodsName)
          this.cdata.barData1 = this.data.map(m => m.ck)
          this.cdata.barData2 = this.data.map(m => m.rk)
          if(this.cdata.barData1.length >= 10){
            this.cdata.end = 10 / this.cdata.barData1.length * 100
          }else{
            this.cdata.end = 100
          }
        this.loading = false
        }
      })
    },
  }
};
</script>

<style lang="scss" scoped>
  .c-head{
    position: absolute;
    top: 50px;
    right: 0;
    display: flex;
  }
  .c-head-left{
    position: absolute;
    top: 50px;
    left: 40px;
    display: flex;
  }
  .df{
    display: flex;
    justify-content: end;
    padding-right: 10px;

  }

  .box-btn{
    height: 20px;
    width: 40px;
    border-radius: 25px;
    line-height: 20px;
    cursor: pointer;
  }
  .bgc-956{
    background: linear-gradient(to right,#3EACE5,#00ae9d);
  }
  .bgc-45b{
    background: linear-gradient(to right,#00ae9d,#fdb933);
  }
  .bgc-8E7{
    background: linear-gradient(to right,#8E7FC2,#47A6E4);
  }
  .bgc-3EA{
    background: linear-gradient(to right,#fdb933,#3EACE5);
  }
  .bgc-ffd{
    background: #ffd400;
  }
  .bgc-33a{
    background-color: #33a3dc;
  }
  .body{
    height: 550px;
  }
  .box-i-time1{
    cursor: pointer!important;
    width: 50px;
    height: 50px;
    position: absolute;
    font-size: 30px;
    left: 30px;
    top:5%;
    z-index: 100;
  }
</style>
