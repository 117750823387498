<template>
  <div id="bottomLeft">
    <div class="bg-color-black">
      <div class="d-flex pt-2 pl-2">

        <div class="d-flex">
          <div class='box-title mb10'>
            <span class="fs-xl text mx-2 "><dv-decoration-11 style="width:200px;height:60px;">{{title}}</dv-decoration-11></span>
          </div>
        </div>
      </div>
      <div class="body">
        <useBar @changeType="changeType"/>
      </div>
    </div>
  </div>
</template>

<script>
import useBar from '../../../../components/useBar'
export default {
  components: {
    useBar
  },
  data() {
    return{
      title: '全部物资消耗排行'
    }
  },
  methods:{
    changeType(type){
      if(type === 2){
        this.title = '全部物资消耗排行'
      }else if(type === 0){
        this.title = '重点物资消耗排行'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
$box-height: 550px;
$box-width: 100%;
#bottomLeft {
  padding: 20px 16px;
  height: $box-height;
  width: $box-width;
  border-radius: 5px;
  .bg-color-black {
    height: $box-height - 35px;
    border-radius: 10px;
  }
  .text {
    color: #c3cbde;
  }
  .chart-box {
    margin-top: 16px;
    width: 170px;
    height: 170px;
    .active-ring-name {
      padding-top: 10px;
    }
  }
  .body{
    height: 550px;
  }

}
</style>
