<template>
    <div id="bottomLeft">
        <div class="bg-color-black">
            <div class="d-flex pt-2 pl-2">
        <span>
        </span>
                <div class="d-flex">
                    <div class='box-title'>
                        <span class="fs-xl text mx-2 "><dv-decoration-11 style="width:200px;height:60px;">成本收益统计</dv-decoration-11></span>
                    </div>
                </div>
            </div>
            <div>
                <ManagementChart />
            </div>
        </div>
    </div>
</template>

<script>
    import ManagementChart from '../../../../components/managementChart'
    export default {
        components: {
            ManagementChart
        }
    }
</script>

<style lang="scss" scoped>
    $box-height: 520px;
    $box-width: 100%;
    #bottomLeft {
        padding: 20px 16px;
        height: $box-height;
        width: $box-width;
        border-radius: 5px;
        .bg-color-black {
            height: $box-height - 35px;
            border-radius: 10px;
        }
        .text {
            color: #c3cbde;
        }
        .chart-box {
            margin-top: 16px;
            width: 170px;
            height: 170px;
            .active-ring-name {
                padding-top: 10px;
            }
        }
    }
</style>
